import { Extras } from "@sentry/types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../config";
import { Document, TEMPLATE_TYPE } from "../stores/models";
import {
  canPrecreateDocumentVersion,
  captureSentryMessage,
  getDocVersionStatus,
  isDocumentCompleted,
} from "../utils";
import {
  useCreateDocumentMutation,
  useCreateDocumentVersionMutation,
} from "./mutations";
import { getDocumentsAsync, useGetDocuments, useGetUser } from "./queries";

const throwNavigationError = (msg: string, extras?: Extras) => {
  captureSentryMessage(msg, extras);
  throw new Error(msg);
};

/**
 * Get refreshed document and documents
 */
const refreshDocumentAndDocuments = async (
  deviceId: string,
  templateId: TEMPLATE_TYPE,
  documentId?: string
) => {
  // Documents must be got with async to avoid stale closure
  let documents = await getDocumentsAsync(deviceId);
  let document =
    documentId === undefined
      ? documents.find((doc) => doc.name === templateId)
      : documents.find((doc) => doc.id === documentId);
  return { documents, document };
};

export const useDocEngine = (deviceId: string) => {
  const navigate = useNavigate();
  const { data: user } = useGetUser();
  const { data: documents } = useGetDocuments(deviceId);

  const [pending, setPending] = useState(false);
  const createDocumentMutation = useCreateDocumentMutation();
  const createDocumentVersionMutation =
    useCreateDocumentVersionMutation(deviceId);

  /**
   * Get an existing doc, or dynamically create one (usually on first navigation)
   * This should likely only be used within navigateToWizardOrQMS.
   */
  const _getOrCreateDocument = async (
    existingDocumentId: string | undefined,
    templateId: TEMPLATE_TYPE,
    createNewMultiInstanceDoc: boolean
  ) => {
    let { document } = await refreshDocumentAndDocuments(
      deviceId,
      templateId,
      existingDocumentId
    );

    // If a `document` id is supplied but it doesn't exist, throw an error
    if (existingDocumentId && !document) {
      throwNavigationError(
        "Cannot navigate to a documentId that doesn't exist",
        {
          existingDocumentId,
        }
      );
    }

    const docStatus =
      documents &&
      document &&
      user &&
      getDocVersionStatus({
        selectedDocVersion: document.versions[0],
        document,
        user,
        templateId,
        documents,
      });

    if (docStatus === "LOCKED") {
      throwNavigationError("Cannot navigate to locked document", {
        templateId,
      });
    }

    // Create a new document if none exists
    if (!document || createNewMultiInstanceDoc) {
      document = await createDocumentMutation.mutateAsync({
        templateId: templateId,
        deviceId,
      });
    }

    return document;
  };

  /**
   * Navigate to the correct view for a document,
   * and potentially create a document version.
   * This should likely only be used within navigateToWizardOrQMS.
   */
  const _navigateToDoc = async (
    templateId: TEMPLATE_TYPE,
    createNewDocVersion: boolean,
    document: Document
  ) => {
    let qmsUrl = ROUTES.QMS_OPEN_DOC.replace(":deviceId", deviceId).replace(
      ":templateId",
      templateId
    );

    // Are we allowed to precreate a new document version? (i.e file uploads shouldn't)
    if (!canPrecreateDocumentVersion(templateId)) {
      return navigate(qmsUrl);
    }

    // Create new documentVersion if there isn't one or if explicitly requested
    if (createNewDocVersion || document.versions.length === 0) {
      await createDocumentVersionMutation.mutateAsync({
        docId: document.id,
      });
    }

    // Refresh documents/document
    const { document: documentUpdated } = await refreshDocumentAndDocuments(
      deviceId,
      templateId,
      document.id
    );
    if (documentUpdated === undefined) {
      return throwNavigationError("Client Error: Document not returned.");
    }

    const docVersion = documentUpdated?.versions[0];
    if (docVersion !== undefined && isDocumentCompleted(document, docVersion)) {
      return navigate((qmsUrl += `?d=${document.id}`));
    } else {
      return navigate(
        ROUTES.TEMPLATE_ASSISTANT.replace(":deviceId", deviceId)
          .replace(":templateId", templateId)
          .replace(":docId", document.id)
      );
    }
  };

  /**
   * Navigation logic for a document
   * Navigates to the QMS or Wizard based on the document state
   * Creates a new document if none exists
   * Creates a new docVersionif necessary
   * @param templateId
   * @param createNewMultiInstanceDoc Explicitly create a new multi-instance document
   * @param createNewDocVersion Explicitly create a new Document Version
   * @param documentId Useful to navigate to a multi instance document
   * @returns void
   */
  const navigateToWizardOrQMS = async ({
    templateId,
    createNewMultiInstanceDoc = false,
    createNewDocVersion = false,
    documentId,
  }: {
    templateId: TEMPLATE_TYPE;
    createNewMultiInstanceDoc?: boolean;
    createNewDocVersion?: boolean;
    documentId?: string;
  }) => {
    setPending(true);
    let document = await _getOrCreateDocument(
      documentId,
      templateId,
      createNewMultiInstanceDoc
    );
    await _navigateToDoc(templateId, createNewDocVersion, document);
    setPending(false);
  };

  return { navigateToWizardOrQMS, pending };
};
