import {
  EditNote,
  Lock,
  NoteAddTwoTone,
  SvgIconComponent,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";

const BaseAnimation = ({
  Icon,
  text,
  subText,
  buttonText,
  buttonOnClick,
  className,
  isLoading,
}: {
  Icon: SvgIconComponent;
  text?: string;
  subText?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
  className?: string;
  isLoading?: boolean;
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-4 ${className || ""}`}
    >
      <Icon className="animate-bounce text-[8rem] opacity-20" />
      <Typography variant="h2" className="opacity-40">
        {text}
      </Typography>
      {subText ? (
        <Typography
          variant="subtitle1"
          className="-mt-2 opacity-40 text-center"
        >
          {subText}
        </Typography>
      ) : null}
      {buttonText ? (
        <LoadingButton
          onClick={buttonOnClick}
          variant="contained"
          loading={isLoading}
        >
          {buttonText}
        </LoadingButton>
      ) : null}
    </div>
  );
};

const NoDocAnimation = ({
  type,
  buttonOnClick,
  className,
  isLoading,
}: {
  type: "NoDoc" | "EditableDoc" | "LockedDoc" | "NoInstance";
  buttonOnClick?: () => void;
  className?: string;
  isLoading?: boolean;
}) => {
  switch (type) {
    case "NoDoc":
      return (
        <BaseAnimation
          Icon={NoteAddTwoTone}
          text="No document selected."
          subText="Select a document from the sidebar on the left to view and edit it."
          className={className}
          isLoading={isLoading}
        />
      );
    case "EditableDoc":
      return (
        <BaseAnimation
          Icon={EditNote}
          text="Document isn't ready yet."
          subText="Please complete all steps."
          buttonOnClick={buttonOnClick}
          buttonText={"Continue Editing"}
          className={className}
          isLoading={isLoading}
        />
      );
    case "LockedDoc":
      return (
        <BaseAnimation
          Icon={Lock}
          text="Document is locked."
          subText="Please complete all documents that are required before this one."
          className={className}
          isLoading={isLoading}
        />
      );
    case "NoInstance":
      return (
        <BaseAnimation
          Icon={NoteAddTwoTone}
          text="No record found."
          subText="Create a new record to view and edit it."
          buttonOnClick={buttonOnClick}
          buttonText={"Create Record"}
          className={className}
          isLoading={isLoading}
        />
      );
    default:
      return <></>;
  }
};

export default NoDocAnimation;
