import { ALWAYS_AVAILABLE_CARDS } from "@config";
import {
  BillingInterval,
  Entitlement,
  EntitlementType,
  Plan,
  SimpleSubscription,
  User,
} from "@models";
import { ROADMAP_TASKS } from "src/config";

export const isPaidDeviceCreation = (user: User, deviceCount: number) => {
  return deviceCount > getActiveDeviceLimit(user);
};
const products = {
  [Plan.PREMIUM]: window._env_.PREMIUM_STRIPE_PRODUCT_ID,
  [Plan.PREMIUM_PLUS]: window._env_.PREMIUM_PLUS_STRIPE_PRODUCT_ID,
};

/**
 * @returns all entitlements for the user's organization
 */
export const getEntitlements = (user: User): Entitlement[] => {
  if (!user.organization.subscription) {
    return [];
  }
  return user.organization.subscription?.subscriptionItems.reduce(
    (acc, item) => {
      return [...acc, ...(item.product.entitlements ?? [])];
    },
    [] as Entitlement[]
  );
};

export const getActiveDeviceLimit = (user: User): number => {
  const plan = getProduct(user);
  if (plan === Plan.FREE) {
    return 1;
  }
  const entitlements = getEntitlements(user);
  const deviceLimit = entitlements.find(
    (entitlement) => entitlement.type === EntitlementType.DEVICE_LIMIT
  );
  return deviceLimit?.value_int ?? 1;
};

/**
 *
 * @returns the number of devices the user's organization has purchased
 */
export const getPurchasedDeviceQuantity = (user: User): number => {
  const plan = getProduct(user);
  if (plan === Plan.FREE) {
    return 1;
  } else {
    return (
      user.organization.subscription?.subscriptionItems.find(
        (item) => item.product.stripeProductId === products[plan]
      )?.productQuantity ?? 1
    );
  }
};

export const getBillingInterval = (user: User): BillingInterval => {
  const plan = getProduct(user);
  if (plan === Plan.FREE) {
    return BillingInterval.YEARLY;
  }

  return (
    user.organization.subscription?.subscriptionItems.find(
      (item) => item.product.stripeProductId === products[plan]
    )?.interval ?? BillingInterval.YEARLY
  );
};

/**
 * @returns the subscription plan for the user's organization.
 * If the user's organization does not have a subscription, returns Plan.FREE.
 */
export const getProduct = (user: User): Plan => {
  if (!user.organization.subscription) {
    return Plan.FREE;
  } else {
    const subscriptionItem =
      user.organization.subscription.subscriptionItems.find(
        (item) =>
          item.product.stripeProductId === products[Plan.PREMIUM] ||
          item.product.stripeProductId === products[Plan.PREMIUM_PLUS]
      );

    if (subscriptionItem) {
      return subscriptionItem.product.stripeProductId === products[Plan.PREMIUM]
        ? Plan.PREMIUM
        : Plan.PREMIUM_PLUS;
    }
  }

  return Plan.FREE;
};

/**
 * @returns the subscription plan, billing interval, and quantity for the user's organization
 */
export const getSubscription = (user: User): SimpleSubscription => {
  const product = getProduct(user);
  const billingInterval = getBillingInterval(user);
  const quantity = getPurchasedDeviceQuantity(user);
  return { product, billingInterval, quantity };
};

export const isUserEntitledToDoc = (user: User, templateId: string) => {
  const entitlements = getEntitlements(user);

  if (
    entitlements.find(
      (entitlement) =>
        entitlement.type === EntitlementType.ALL_DOCUMENTS_UNLOCKED
    )
  ) {
    return true;
  }

  return Boolean(
    ROADMAP_TASKS.find(
      (task) =>
        task.id === templateId && ALWAYS_AVAILABLE_CARDS.includes(task.card)
    )
  );
};
