import { Document, DocumentVersion, TEMPLATE_TYPE } from "@models";
import { TemplateElement } from "@types";
import {
  getDocumentAnswerMap,
  getDocumentStepsFromConfig,
} from "src/utils/documents";

export const isStepComplete = (
  step: TemplateElement,
  answer: string | undefined
) => {
  if (!step.required) return true;

  // If the trimmed answer is empty and the step is required and the default value is not set than the step is not completed
  const answerIsDefinedAndIsNotEmpty = !!answer && answer?.trim() !== "";

  return (
    (answerIsDefinedAndIsNotEmpty || !!step.element.options.default) &&
    step.required
  );
};

export const allStepsCompleted = (
  document: Document,
  documentVersion: DocumentVersion
) => {
  const values = Object.entries(getDocumentAnswerMap(documentVersion));

  const steps = getDocumentStepsFromConfig(document.name as TEMPLATE_TYPE);

  return steps.every((step) => {
    const [_, answer] = values.find(([k]) => k === step.id) || [];
    return isStepComplete(step, answer);
  });
};
