import {
  CheckRounded,
  CloudUploadOutlined,
  ErrorOutline,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  TextField,
} from "@mui/material";
import { isArray } from "lodash";
import mermaid from "mermaid";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "..";
import { Device, TEMPLATE_TYPE } from "../../stores/models";
import { DocumentDataKey, TemplateElement } from "../../types";
import { parseMarkdownTable } from "../../utils";
import { Table } from "./Elements/Table";
import { Toolbar } from "./Toolbar";
import { handleCSVUpload } from "./utils";

type Props = {
  // element: FormElement;
  value: string;
  hasInlineSuggestion?: boolean;
  disabled?: boolean;
  onChange: (id: string, value: string) => void;
  onRegenerateSuggestion: () => void;
  onReRunTransformer: () => void;
  onApplySuggestion?: (id: DocumentDataKey, value: string) => void;
  step: TemplateElement;
  device: Device;
  suggestions: {
    [id: string]: {
      completed: boolean;
      value: string;
      saved: boolean;
      applied: boolean;
      loading: boolean;
    };
  };
  onUndo: () => void;
  onRedo: () => void;
};

export const ElementMapper: React.FC<Props> = ({
  disabled,
  hasInlineSuggestion = false,
  onChange,
  onRegenerateSuggestion,
  onReRunTransformer,
  onUndo,
  onRedo,
  step,
  suggestions,
  value,
}) => {
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { templateId } = useParams<{
    templateId: TEMPLATE_TYPE;
  }>();

  if (!templateId) {
    throw new Error("No template id specified");
  }
  const { element } = step;

  const hasTransformer = element.transformerConfig?.type === "default";
  const isFetchingSuggestion = suggestions[element.id].loading;

  const renderElement = () => {
    switch (element.type) {
      case "fileUpload":
        const [preview, setPreview] = useState("");
        const [uploadStatus, setUploadStatus] = useState("idle");

        const handleFileChange = async (
          event: ChangeEvent<HTMLInputElement>
        ) => {
          setUploadStatus("loading");
          const file = event.target.files?.[0];
          if (!file) {
            setUploadStatus("failed");
            return;
          }
          try {
            const { result } = await element.options.onUpload(file);
            setUploadStatus("completed");

            onChange(element.id, result);
          } catch (error) {
            setUploadStatus("failed");
            return;
          }
        };

        useEffect(() => {
          parsePreview(value);
        }, [value]);

        const parsePreview = (text: string) => {
          let values = [];
          try {
            values = JSON.parse(text);
          } catch (error) {
            values = parseMarkdownTable(text);
          }
          setPreview(values.length + " publications uploaded.");
        };

        return (
          <div key={element.id} className="flex flex-col gap-y-3">
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadOutlined />}
            >
              Upload file
              <input
                type="file"
                className="hidden"
                accept={element.options.allowedFileTypes || undefined}
                onChange={handleFileChange}
              />
            </Button>

            {/* {uploadStatus === "loading" && <LinearProgress />} */}
            {uploadStatus !== "failed" &&
              (uploadStatus === "completed" || value) && (
                <Alert
                  icon={<CheckRounded fontSize="inherit" />}
                  severity="success"
                >
                  {preview}
                </Alert>
              )}
            {uploadStatus === "failed" && (
              <Alert
                icon={<ErrorOutline fontSize="inherit" />}
                severity="error"
              >
                Failed to process the uploaded file.
              </Alert>
            )}
          </div>
        );
      case "mermaid":
        const [diagram, setDiagram] = useState<string>("");

        useEffect(() => {
          const render = async () => {
            // Generate a random ID for Mermaid to use.
            const id = `mermaid-svg-${Math.round(Math.random() * 10000000)}`;
            mermaid.mermaidAPI.initialize({
              flowchart: {
                useMaxWidth: true,
                htmlLabels: true,
                curve: "basis",
              },
            });

            // Confirm the diagram is valid before rendering since it could be invalid
            // while streaming, or if the LLM "hallucinates" an invalid diagram.
            if (await mermaid.parse(value, { suppressErrors: true })) {
              const { svg } = await mermaid.render(id, value);
              setDiagram(svg);
            } else {
              setDiagram("Error rendering diagram");
            }
          };
          render();
        }, [value]);

        return (
          <div key={element.id} className="flex flex-col ">
            {isFetchingSuggestion && (
              <Skeleton variant="rectangular" width="100%" height={200} />
            )}
            {!isFetchingSuggestion && (
              <div className="border-1 w-full rounded border-solid border-slate-100 p-2">
                <div dangerouslySetInnerHTML={{ __html: diagram ?? "" }} />
              </div>
            )}
          </div>
        );

      case "table":
        return (
          <Table
            key={element.id}
            ref={tableRef}
            element={element}
            value={value}
            onChange={onChange}
            isFetchingSuggestion={isFetchingSuggestion}
          />
        );

      case "textField":
        return (
          <div key={element.id} className="flex flex-col">
            <TextField
              hiddenLabel
              key={element.id}
              id={element.id}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(element.id, event.target.value)
              }
              // helperText={element.options.helperText}
              multiline={!!element.options.rows}
              fullWidth
              value={value}
              minRows={element.options.rows}
              disabled={disabled}
              // className="whitespace-normal"
            />
          </div>
        );
      case "select":
        // const defaultValue = element.options.default
        //   ? element.options.options[element.options.default[0]]
        //   : element.options.options[0];
        const stringOrArrayValue =
          typeof value === "string" && element.options.multiple
            ? value.trim().split(",")
            : value;

        const handleChange = (event: SelectChangeEvent<unknown>) => {
          let value = event.target.value;

          if (element.options.multiple && isArray(value)) {
            const arrayValue = value as string[];
            value = arrayValue.filter((v) => v !== "").join(",");
          }

          onChange(element.id, value as string);
        };

        return (
          <FormControl className="flex w-full flex-col ">
            <TextField
              disabled={disabled}
              select
              SelectProps={{
                multiple: element.options.multiple,
                renderValue: (stringOrArrayValue: unknown) => {
                  let value;

                  if (typeof stringOrArrayValue === "string")
                    value = stringOrArrayValue;
                  if (Array.isArray(stringOrArrayValue))
                    value = stringOrArrayValue.join(", ");
                  return value;
                },

                onChange: handleChange,
              }}
              hiddenLabel
              id={element.id}
              value={stringOrArrayValue}
            >
              {element.options.options.map((name) => (
                <MenuItem key={name} value={name}>
                  {element.options.multiple && (
                    <Checkbox checked={stringOrArrayValue.indexOf(name) > -1} />
                  )}

                  {name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        );
      default:
        return null;
    }
  };

  const importAnswer = async (file: File) => {
    try {
      hideSnackbar();
      const importedAnswer = await handleCSVUpload(file, templateId, step.id);
      onChange(step.id, importedAnswer);
    } catch (error: any) {
      showSnackbar(error.message || "Please upload a valid CSV file.");
    }
  };

  const handleCopyTable = useCallback(() => {
    if (element.type === "table" && tableRef.current) {
      tableRef.current.copyTable();
    }
  }, [element.type]);

  const tableRef = React.useRef<{ copyTable: () => void } | null>(null);

  return (
    <div className="flex flex-col">
      <Toolbar
        hasCSVUpload={element.type === "table"}
        hasInlineSuggestion={hasInlineSuggestion}
        hasTransformer={hasTransformer}
        onRegenerateSuggestion={onRegenerateSuggestion}
        onReRunTransformer={onReRunTransformer}
        onCSVUpload={(file: File) => importAnswer(file)}
        onUndo={element.type === "table" ? onUndo : undefined}
        onRedo={element.type === "table" ? onRedo : undefined}
        onCopyTable={element.type === "table" ? handleCopyTable : undefined}
      />
      {renderElement()}
    </div>
  );
};
