import { RoadmapCard, RoadmapStageId } from "@config";
import { useGetDocuments, useGetTasks, useGetUser } from "@hooks";
import { Skeleton } from "@mui/material";
import { TaskAccordion } from "src/components/Roadmap/Task/TaskAccordion";

type Props = {
  cardConfig: { card: RoadmapCard; phase: RoadmapStageId };
  deviceId: string;
  open: boolean;
  handleCloseCardDialog: () => void;
};

export const TaskContent = ({
  deviceId,
  cardConfig,
  handleCloseCardDialog,
}: Props) => {
  const { data: user } = useGetUser();

  const { data: tasks } = useGetTasks(deviceId);
  const { data: documents } = useGetDocuments(deviceId);

  if (tasks && documents && user)
    return (
      cardConfig.card.tasks
        // Only list tasks on the card where there is no visibility condition or where the condition is met
        .filter((task) => {
          if (task.visibilityCondition) {
            const answer = documents
              .map((doc) =>
                doc.versions[0]?.answers.find(
                  (answer) =>
                    answer.element === task.visibilityCondition?.dataKey
                )
              )
              .find((answer) => answer !== undefined);

            if (!answer?.answer) {
              // If the answer is not present, we should not show the task until we have more certainty
              return false;
            }

            return task.visibilityCondition.isVisible(answer.answer);
          }
          return true;
        })
        .map((task) => (
          <TaskAccordion
            key={task.id}
            task={task}
            handleCloseCardDialog={handleCloseCardDialog}
            documents={documents}
            user={user}
            deviceId={deviceId}
          />
        ))
    );

  return (
    <>
      <Skeleton
        variant="rectangular"
        key={0}
        width="100%"
        height={28}
        className="mb-2"
      />
      <Skeleton
        variant="rectangular"
        key={1}
        width="90%"
        height={28}
        className="mb-2"
      />
      <Skeleton
        variant="rectangular"
        key={2}
        width="100%"
        height={28}
        className="mb-2"
      />
    </>
  );
};
