import { RoadmapCardId } from ".";
import { TEMPLATE_TYPE } from "../../stores/models";
import { DocumentDataKey } from "../../types";

export type RoadmapTaskId =
  | TEMPLATE_TYPE
  | "intended-use"
  | "basic-characteristics-technical-file-filled"
  | "sop-software-problem-resolution"
  | "sop-integrated-software-development"
  | "software-processes-technical-file-filled"
  | "mdr-device-classification"
  | "device-classification-technical-file-filled"
  | "list-of-hazard-related-use-scenarios"
  | "establish-risk-management-system-technical-file-filled"
  | "user-needs-list"
  | "user-needs-technical-file-filled"
  | "software-system-test-plan"
  | "software-requirements-list"
  | "sop-deployment"
  | "software-development-and-maintenance-plan"
  | "software-design-and-development-technical-file-filled"
  | "quality-manual-policy-objectives"
  | "sop-document-record-control"
  | "sop-human-resources-administration"
  | "establish-quality-management-system-technical-file-filled"
  | "sop-purchasing"
  | "sop-sales"
  | "purchasing-sales-and-suppliers-technical-file-filled"
  | "software-architecture-checklist"
  | "software-architecture"
  | "software-requirements-review-checklist"
  | "supplier-checklist"
  | "soup-list"
  | "usability-test-plan"
  | "usability-testing-protocol"
  | "perform-usability-test"
  | "usability-testing-report"
  | "usability-and-human-factors-engineering-technical-file-filled"
  | "clinical-investigations-consult-with-formlyai"
  | "algorithm-validation-report"
  | "bug-fixes-documentation-list"
  | "software-verification-and-validation-technical-file-filled"
  | "sop-feedback-management"
  | "sop-vigilance"
  | "sop-capa"
  | "incident-assessment-form"
  | "field-safety-notice"
  | "list-capa"
  | "complaints-and-feedback-technical-file-filled"
  | "list-of-training-documentation"
  | "training-record"
  | "risk-management-report"
  | "list-of-known-anomalies"
  | "checklist-software-release"
  | "change-evaluation-list"
  | "instructions-for-use"
  | "sop-post-market-surveillance"
  | "post-market-surveillance-plan"
  | "post-market-clinical-follow-up-plan"
  | "periodic-safety-update-report"
  | "post-market-clinical-follow-up-report"
  | "post-market-surveillance-technical-file-filled"
  | "sop-product-registration-and-certification"
  | "udi-creation"
  | "eudamed-registration"
  | "eudamed-registration-technical-file-filled"
  | "technical-file-generated"
  | "checklist-mdr"
  | "general-safety-and-performance-requirements-technical-file-filled"
  | "sop-clinical-evaluation"
  | "clinical-evaluation-plan"
  | "clinical-evaluation-report"
  | "checklist-literature-evaluation"
  | "clinical-evaluation-technical-file-filled"
  | "sop-management-review"
  | "list-of-regulatory-requirements"
  | "sop-update-of-regulations"
  | "sop-internal-audit"
  | "audit-plan"
  | "audit-program"
  | "management-review-report"
  | "list-of-medical-devices"
  | "sop-change-management";

export type RoadmapTaskConfig = {
  title: string;
  link?: string;
  id: RoadmapTaskId;
  card: RoadmapCardId;
  visibilityCondition?: {
    dataKey: DocumentDataKey;
    isVisible: (value: string) => boolean;
  };
};

export const ROADMAP_TASKS: RoadmapTaskConfig[] = [
  {
    title: "Complete the Intended Use.",
    id: TEMPLATE_TYPE.INTENDED_USE,
    card: "device-basic-characteristics",
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "basic-characteristics-technical-file-filled",
  //   card: "device-basic-characteristics",
  //   link: ROUTES.DEVICE_DESCRIPTION_ONE,
  // },
  {
    title: "Complete the SOP Software Problem Resolution.",
    id: TEMPLATE_TYPE.SOP_SOFTWARE_PROBLEM_RESOLUTION,
    card: "software-processes",
  },
  {
    title: "Complete the SOP Software Development.",
    id: TEMPLATE_TYPE.SOP_INTEGRATED_SOFTWARE_DEVELOPMENT,
    card: "software-processes",
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "software-processes-technical-file-filled",
  //   card: "software-processes",
  //   link: ROUTES.SOFTWARE,
  // },
  {
    title: "Complete the MDR Device Classification.",
    id: "mdr-device-classification",
    card: "device-classification",
  },
  // {
  //   title:
  //     "Complete the corresponding sections in the technical file and justification based on criteria in the guide",
  //   id: "device-classification-technical-file-filled",
  //   card: "device-classification",
  //   link: ROUTES.DEVICE_DESCRIPTION_TWO,
  // },
  {
    title: "Complete the Risk Management Plan.",
    id: TEMPLATE_TYPE.RISK_MANAGEMENT_PLAN,
    card: "establish-risk-management-system",
  },
  {
    title: "Complete the Risk Assessment.",
    id: TEMPLATE_TYPE.RISK_ASSESSMENT,
    card: "establish-risk-management-system",
  },
  {
    title: "Complete the SOP Risk Management.",
    id: TEMPLATE_TYPE.SOP_RISK_MANAGEMENT,
    card: "establish-risk-management-system",
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "establish-risk-management-system-technical-file-filled",
  //   card: "establish-risk-management-system",
  //   link: ROUTES.RISK_MANAGEMENT,
  // },
  {
    title: "Complete the User Needs List.",
    id: TEMPLATE_TYPE.USER_NEEDS_LIST,
    card: "user-needs",
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "user-needs-technical-file-filled",
  //   card: "user-needs",
  // },
  {
    title: "Complete the Software System Test Plan.",
    id: "software-system-test-plan",
    card: "software-verification-and-validation",
  },
  {
    id: "software-architecture",
    title: "Complete the Software Architecture.",
    card: "software-design-and-development",
  },
  {
    title: "Complete the Software Requirements List.",
    id: "software-requirements-list",
    card: "software-design-and-development",
  },
  {
    title: "Complete the SOP Deployment.",
    id: TEMPLATE_TYPE.SOP_DEPLOYMENT,
    card: "software-validation-and-deployment",
  },
  {
    title: "Complete the Deployment Evaluation Checklist.",
    id: TEMPLATE_TYPE.DEPLOYMENT_EVALUATION_CHECKLIST,
    card: "software-validation-and-deployment",
  },
  {
    title: "Complete the SOP Software Validation.",
    id: TEMPLATE_TYPE.SOP_SOFTWARE_VALIDATION,
    card: "software-validation-and-deployment",
  },
  {
    title: "Complete the List of Validated Software.",
    id: TEMPLATE_TYPE.LIST_OF_VALIDATED_SOFTWARE,
    card: "software-validation-and-deployment",
  },
  {
    title:
      "Use the Software Validation Form to check if you need to validate any additional software, if yes complete the document and copy it to your document storage. If not, you can check the task. Use a copy of the final document as template for any additional software that needs validation according to SOP Software Validation.",
    id: TEMPLATE_TYPE.SOFTWARE_VALIDATION_FORM,
    card: "software-validation-and-deployment",
  },
  {
    title: "Complete the Software Development and Maintenance Plan.",
    id: "software-development-and-maintenance-plan",
    card: "software-design-and-development",
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "software-design-and-development-technical-file-filled",
  //   card: "software-design-and-development",
  //   link: ROUTES.SOFTWARE,
  // },
  {
    title: "Complete the Quality Manual.",
    id: TEMPLATE_TYPE.QUALITY_MANUAL_POLICY_OBJECTIVES,
    card: "establish-quality-management-system",
  },
  {
    title: "Complete the SOP Document and Record Control.",
    id: TEMPLATE_TYPE.SOP_DOCUMENT_RECORD_CONTROL,
    card: "establish-quality-management-system",
  },
  {
    title: "Review out the SOP Human Resources.",
    id: TEMPLATE_TYPE.SOP_HUMAN_RESOURCES_ADMINISTRATION,
    card: "establish-quality-management-system",
  },
  // {
  //   title: "Add a simple QMS summary to Technical File",
  //   id: "establish-quality-management-system-technical-file-filled",
  //   card: "establish-quality-management-system",
  //   link: ROUTES.DEVICE_DESCRIPTION_ONE,
  // },
  {
    title: "Complete the SOP Purchasing.",
    id: "sop-purchasing",
    card: "purchasing-sales-and-suppliers",
  },
  {
    title: "Complete the SOP Sales.",
    id: "sop-sales",
    card: "purchasing-sales-and-suppliers",
  },
  {
    title:
      "Complete the List of Qualified Suppliers template with all of your suppliers.",
    id: TEMPLATE_TYPE.LIST_OF_QUALIFIED_SUPPLIERS,
    card: "purchasing-sales-and-suppliers",
  },
  {
    title:
      "Complete the Supplier Checklist template for your current suppliers and create a new version each time a new supplier is onboarded.",
    id: "supplier-checklist",
    card: "purchasing-sales-and-suppliers",
  },
  // {
  //   title: "Fill out any subcontractors or suppliers in the technical file",
  //   id: "purchasing-sales-and-suppliers-technical-file-filled",
  //   card: "purchasing-sales-and-suppliers",
  //   link: ROUTES.SUBCONTRACTORS,
  // },
  {
    title: "Complete the Software Architecture Checklist.",
    id: "software-architecture-checklist",
    card: "design-stage-checklists",
  },
  {
    title: "Complete the Software Requirements Review Checklist.",
    id: TEMPLATE_TYPE.SOFTWARE_REQUIREMENTS_REVIEW_CHECKLIST,
    card: "design-stage-checklists",
  },
  {
    title: "Complete the SOUP List.",
    id: TEMPLATE_TYPE.SOUP_LIST,
    card: "design-stage-checklists",
  },
  {
    title: "Complete the User Needs Checklist.",
    id: TEMPLATE_TYPE.USER_NEEDS_CHECKLIST,
    card: "design-stage-checklists",
  },
  {
    title: "Complete the Usability Evaluation Plan.",
    id: TEMPLATE_TYPE.USABILITY_EVALUATION_PLAN,
    card: "usability-and-human-factors-engineering",
  },
  {
    title: "Complete the Usability Evaluation Protocol.",
    id: TEMPLATE_TYPE.USABILITY_EVALUATION_PROTOCOL,
    card: "usability-and-human-factors-engineering",
  },
  // {
  //   title: "Perform your usability test according to your protocol",
  //   id: "perform-usability-test",
  //   card: "usability-and-human-factors-engineering",
  // },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "usability-and-human-factors-engineering-technical-file-filled",
  //   card: "usability-and-human-factors-engineering",
  //   link: ROUTES.USABILITY,
  // },
  {
    title:
      "Consult with FormlyAI as to whether a clinical investigation is necessary for your device",
    id: "clinical-investigations-consult-with-formlyai",
    card: "clinical-investigations",
  },
  {
    title: "Complete the Bug Fixes Report for any bugs you encounter.",
    id: TEMPLATE_TYPE.BUG_FIX_REPORT,
    card: "software-verification-and-validation",
  },
  {
    title: "Complete the SOP Change Management.",
    id: "sop-change-management",
    card: "software-verification-and-validation",
  },
  {
    title:
      "Complete the Change Request for any changes you need to make to the released product.",
    id: TEMPLATE_TYPE.CHANGE_REQUEST,
    card: "software-verification-and-validation",
  },

  {
    title:
      "Complete the Change Evaluation Report. Create one for any changes you need to make to the released product.",
    id: TEMPLATE_TYPE.CHANGE_EVALUATION_REPORT,
    card: "software-verification-and-validation",
  },

  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "software-verification-and-validation-technical-file-filled",
  //   card: "software-verification-and-validation",
  //   link: ROUTES.SOFTWARE_THREE,
  // },
  {
    title: "Complete the SOP Feedback and Complaint Management.",
    id: TEMPLATE_TYPE.SOP_FEEDBACK_MANAGEMENT,
    card: "complaints-and-feedback",
  },
  {
    title: "Complete the SOP Vigilance.",
    id: TEMPLATE_TYPE.SOP_VIGILANCE,
    card: "complaints-and-feedback",
  },
  {
    title: "Complete the SOP Corrective and Preventive Action (CAPA).",
    id: TEMPLATE_TYPE.SOP_CAPA,
    card: "complaints-and-feedback",
  },
  {
    title:
      "Complete the Incident Assessment Form once you need to report any incidents.",
    id: TEMPLATE_TYPE.INCIDENT_ASSESSMENT_FORM,
    card: "complaints-and-feedback",
  },
  {
    title:
      "Complete the Field Safety Notice Form once you need to report a field safety notice.",
    id: TEMPLATE_TYPE.FIELD_SAFETY_NOTICE,
    card: "complaints-and-feedback",
  },
  {
    title: "Complete the CAPA Plan.",
    id: TEMPLATE_TYPE.CAPA_PLAN,
    card: "complaints-and-feedback",
  },
  {
    title: "Complete the CAPA Report.",
    id: TEMPLATE_TYPE.CAPA_REPORT,
    card: "complaints-and-feedback",
  },
  {
    title:
      "Complete the List of CAPAs and update with any CAPAs you encounter.",
    id: TEMPLATE_TYPE.LIST_CAPA,
    card: "complaints-and-feedback",
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "complaints-and-feedback-technical-file-filled",
  //   card: "complaints-and-feedback",
  //   link: ROUTES.MARKET_HISTORY,
  // },
  {
    title:
      "Complete the List of Employee Training and update it with any training you do.",
    id: TEMPLATE_TYPE.LIST_OF_EMPLOYEE_TRAINING,
    card: "training",
  },
  {
    title:
      "Complete the Group Training Record and update it with any training you do.",
    id: TEMPLATE_TYPE.GROUP_TRAINING_RECORD,
    card: "training",
  },
  {
    title: "Complete the Required Documentation Training by Functional Group.",
    id: TEMPLATE_TYPE.REQUIRED_DOCUMENTATION_TRAINING_BY_FUNCTIONAL_GROUP,
    card: "training",
  },
  {
    title: "Complete the Usability Evaluation Report.",
    id: TEMPLATE_TYPE.USABILITY_EVALUATION_REPORT,
    card: "testing-and-validation-stage-checklists",
  },
  {
    title: "Complete the Software System Test Report.",
    id: TEMPLATE_TYPE.SOFTWARE_SYSTEM_TEST_REPORT,
    card: "testing-and-validation-stage-checklists",
  },
  {
    title: "Complete the Risk Management Report.",
    id: TEMPLATE_TYPE.RISK_MANAGEMENT_REPORT,
    card: "testing-and-validation-stage-checklists",
  },
  {
    title: "Complete the List of Known Anomalies.",
    id: TEMPLATE_TYPE.LIST_OF_KNOWN_ANOMALIES,
    card: "testing-and-validation-stage-checklists",
  },
  {
    title: "Complete the Software Release Checklist.",
    id: TEMPLATE_TYPE.CHECKLIST_SOFTWARE_RELEASE,
    card: "software-release",
  },
  {
    title: "Complete the Release Notes.",
    id: TEMPLATE_TYPE.RELEASE_NOTES,
    card: "software-release",
  },
  {
    title:
      "Complete the Device Label and make it accessible fro your user somewhere within your software.",
    id: TEMPLATE_TYPE.DEVICE_LABEL,
    card: "software-release",
  },
  {
    title: "Complete the Instructions for Use.",
    id: TEMPLATE_TYPE.INSTRUCTIONS_FOR_USE,
    card: "labeling-instructions-for-use-and-manuals",
  },
  {
    title:
      "Upload the Instructions for Use translated into the language of the country you will first market your device.",
    id: TEMPLATE_TYPE.PRIMARY_LANGUAGE_TRANSLATED_IFU,
    card: "labeling-instructions-for-use-and-manuals",
  },
  {
    title:
      "Upload the Instructions for Use translated into all other languages as one composite. You only need to translate the document for the countries languages that you plan to sell your device in.",
    id: TEMPLATE_TYPE.OTHER_LANGUAGES_TRANSLATED_IFUS,
    card: "labeling-instructions-for-use-and-manuals",
  },
  {
    title: "Complete the Instructions for Use.",
    id: TEMPLATE_TYPE.INSTRUCTIONS_FOR_USE,
    card: "labeling-instructions-for-use-and-manuals",
  },
  {
    title: "Complete the SOP Post-Market Surveillance.",
    id: TEMPLATE_TYPE.SOP_POST_MARKET_SURVEILLANCE,
    card: "post-market-surveillance",
  },
  {
    title: "Complete the Post-Market Surveillance Plan.",
    id: TEMPLATE_TYPE.POST_MARKET_SURVEILLANCE_PLAN,
    card: "post-market-surveillance",
  },
  {
    title:
      "Two years after certification, complete the Post-Market Surveillance Report.",
    id: TEMPLATE_TYPE.POST_MARKET_SURVEILLANCE_REPORT,
    card: "post-market-surveillance",

    // Only required for class I
    visibilityCondition: {
      dataKey: "device-classification",
      isVisible: (answer: string) => {
        return "Class I" === answer;
      },
    },
  },
  {
    title: "Complete the Post-Market Clinical Follow-Up Plan.",
    id: TEMPLATE_TYPE.POST_MARKET_CLINICAL_FOLLOW_UP_PLAN,
    card: "post-market-surveillance",
  },
  {
    title:
      "One year after certification, complete the Periodic Safety Update Report.",
    id: TEMPLATE_TYPE.PERIODIC_SAFETY_UPDATE_REPORT,
    card: "post-market-surveillance",

    // Only required for class I
    visibilityCondition: {
      dataKey: "device-classification",
      isVisible: (answer: string) => {
        return ["Class IIa", "Class IIb", "Class III"].includes(answer);
      },
    },
  },
  {
    title:
      "One year after certification, complete the Post-Market Clinical Follow-up Report.",
    id: TEMPLATE_TYPE.POST_MARKET_CLINICAL_FOLLOW_UP_REPORT,
    card: "post-market-surveillance",
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "post-market-surveillance-technical-file-filled",
  //   card: "post-market-surveillance",
  //   link: ROUTES.POST_MARKET,
  // },

  {
    title: "Complete the SOP Clinical Evaluation.",
    id: TEMPLATE_TYPE.SOP_CLINICAL_EVALUATION,
    card: "clinical-evaluation",
  },
  {
    title: "Complete the Clinical Evaluation Plan.",
    id: TEMPLATE_TYPE.CLINICAL_EVALUATION_PLAN,
    card: "clinical-evaluation",
  },
  {
    title: "Complete the Clinical Evaluation Report.",
    id: TEMPLATE_TYPE.CLINICAL_EVALUATION_REPORT,
    card: "clinical-evaluation",
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",

  //   id: "clinical-evaluation-technical-file-filled",
  //   card: "clinical-evaluation",
  //   link: ROUTES.CLINICAL_EVALUATION,
  // },

  {
    title:
      "Complete the General Safety and Performance Requirements Checklist.",
    id: TEMPLATE_TYPE.GSPR_CHECKLIST,
    card: "general-safety-and-performance-requirements-checklist",
  },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "general-safety-and-performance-requirements-technical-file-filled",
  //   card: "general-safety-and-performance-requirements-checklist",

  //   link: ROUTES.GENERAL_SAFETY_AND_PERFORMANCE,
  // },

  {
    title: "Complete the SOP Management Review.",
    id: TEMPLATE_TYPE.SOP_MANAGEMENT_REVIEW,
    card: "audits-and-review",
  },
  {
    title: "Complete the List of Regulatory Requirements.",
    id: TEMPLATE_TYPE.LIST_OF_REGULATORY_REQUIREMENTS,
    card: "audits-and-review",
  },
  {
    title: "Complete the SOP Update of Regulations.",
    id: TEMPLATE_TYPE.SOP_UPDATE_OF_REGULATIONS,
    card: "audits-and-review",
  },
  {
    title: "Complete the SOP Internal Audit.",
    id: TEMPLATE_TYPE.SOP_INTERNAL_AUDIT,
    card: "audits-and-review",
  },
  {
    title: "Complete the Audit Plan.",
    id: TEMPLATE_TYPE.AUDIT_PLAN,
    card: "audits-and-review",
  },
  {
    title: "Complete the Audit Program.",
    id: TEMPLATE_TYPE.AUDIT_PROGRAM,
    card: "audits-and-review",
  },
  {
    title: "Complete the Audit Report.",
    id: TEMPLATE_TYPE.AUDIT_REPORT,
    card: "audits-and-review",
  },
  {
    title: "Complete the Management Review Report.",
    id: TEMPLATE_TYPE.MANAGEMENT_REVIEW_REPORT,
    card: "audits-and-review",
  },
  {
    title: "Complete the List of Medical Devices.",
    id: TEMPLATE_TYPE.LIST_OF_MEDICAL_DEVICES,
    card: "software-release",
  },

  {
    title: "Complete the EU Declaration of Conformity.",
    id: TEMPLATE_TYPE.DECLARATION_OF_CONFORMITY,
    card: "declaration-of-conformity",
  },
  {
    title: "Complete the SOP Product Registration and Certification.",
    id: "sop-product-registration-and-certification",
    card: "udi-creation-and-eudamed-registration",
  },
  {
    title: "Follow the steps in the UDI Creation guide.",
    id: TEMPLATE_TYPE.UDI_CREATION,
    card: "udi-creation-and-eudamed-registration",
  },
  // {
  //   title:
  //     "Register your product with EUDAMED at their website <a href='https://webgate.ec.europa.eu/eudamed/landing-page#/' target='_blank'>here</a>",
  //   id: "eudamed-registration",
  //   card: "udi-creation-and-eudamed-registration",
  // },
  // {
  //   title: "Complete the corresponding sections in the technical file",
  //   id: "eudamed-registration-technical-file-filled",
  //   card: "udi-creation-and-eudamed-registration",
  //   link: ROUTES.DEVICE_DESCRIPTION_ONE,
  // },
  {
    title: "Complete the Technical File.",
    id: TEMPLATE_TYPE.TECHNICAL_FILE,
    card: "generate-technical-file",
  },
  {
    title: "Upload your Physician's handbook Handbook file.",
    id: TEMPLATE_TYPE.PHYSICIANS_HANDBOOK,
    card: "labeling-instructions-for-use-and-manuals",
  },
  {
    title: "Upload your Additional Software Test Plans file.",
    id: TEMPLATE_TYPE.ADDITIONAL_SOFTWARE_TEST_PLANS,
    card: "software-verification-and-validation",
  },
  {
    title: "Upload your Additional Software Test Reports file.",
    id: TEMPLATE_TYPE.ADDITIONAL_SOFTWARE_TEST_REPORTS,
    card: "software-verification-and-validation",
  },
  {
    title: "Upload your Marketing Content file.",
    id: TEMPLATE_TYPE.MARKETING_CONTENT,
    card: "labeling-instructions-for-use-and-manuals",
  },
  {
    title: "Upload your User Manual file.",
    id: TEMPLATE_TYPE.USER_MANUAL,
    card: "labeling-instructions-for-use-and-manuals",
  },
];
