import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isStepComplete } from "src/utils/step";
import {
  MultiStepFormSkeleton,
  UpdateDeviceDescriptionModal,
} from "../components";
import { Step } from "../components/Form/Step";
import {
  getAnswer,
  getAnswerFromDocumentVersion,
  getSuggestion,
} from "../components/Form/utils";
import { ASSISTANT_CONFIG } from "../config";
import {
  useDocEngine,
  useGetDevice,
  useGetDocument,
  useGetDocuments,
  useGetTasks,
  useGetUser,
  usePatchDocumentVersionMutation,
  useSaveAnswerMutation,
  useSaveSuggestion,
} from "../hooks";
import { useStore } from "../stores";
import {
  Device,
  Document,
  DocumentAnswer,
  DocumentVersion,
  TEMPLATE_TYPE,
} from "../stores/models";
import {
  DocumentDataKey,
  FileUploadElement,
  MarkdownTableElement,
  MermaidElement,
  SelectElement,
  SuggestionState,
  TableElement,
  TemplateElement,
  TextFieldElement,
} from "../types";
import { getDocumentStepsFromConfig, updateSearchParams } from "../utils";

export type Element =
  | TextFieldElement
  | SelectElement
  | MarkdownTableElement
  | MermaidElement
  | TableElement
  | FileUploadElement;

type Answers = Partial<{
  [id in DocumentDataKey]: string | null;
}>;

export const MultiStepForm: React.FC = () => {
  const { documentStore } = useStore();
  const {
    deviceId = "",
    templateId = "",
    docId = "",
  } = useParams<{
    deviceId: string;
    templateId: TEMPLATE_TYPE;
    docId?: string;
  }>();

  const docEngine = useDocEngine(deviceId);

  if (!templateId) {
    throw new Error("No template id specified");
  }
  if (!deviceId) {
    throw new Error("No device id specified");
  }

  const navigate = useNavigate();
  const steps = getDocumentStepsFromConfig(templateId);

  if (steps.length === 0) {
    console.error("No steps found for template", templateId);
    navigate(-1);
  }

  const docConfig = ASSISTANT_CONFIG[templateId];

  if (!docConfig) {
    throw new Error("No document config found");
  }

  const containerRef = React.useRef<HTMLDivElement>(null);

  let [searchParams, setSearchParams] = useSearchParams();
  const currentStep = parseInt(searchParams.get("step") || "1");
  const step = steps[currentStep - 1];
  const [isGenerating, setIsGenerating] = useState(false);
  const [answers, setAnswers] = useState<Answers>({});
  const [debouncedAnswers, setDebouncedAnswers] = useState<Answers>({});
  /**
   * QUERIES
   */
  const { data: user } = useGetUser();
  const { data: device } = useGetDevice(deviceId);
  const { data: document } = useGetDocument(deviceId, docId);
  const { data: documents } = useGetDocuments(deviceId);
  const documentVersion = document?.versions[0];
  const { data: tasks } = useGetTasks(deviceId);

  /**
   * MUTATIONS
   */
  const saveSuggestion = useSaveSuggestion(steps[currentStep - 1]);
  const saveAnswerMutation = useSaveAnswerMutation();
  const patchDocumentMutation = usePatchDocumentVersionMutation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedAnswers((prev) => ({ ...prev, [step.id]: answers[step.id] }));
    }, 3000);

    return () => clearTimeout(timer);
  }, [answers[step.id]]);

  useEffect(() => {
    const answer = debouncedAnswers[step.id];
    if (typeof answer === "string" && documentVersion) {
      handleSaveAnswer(answer, step, documentVersion);
    }
  }, [debouncedAnswers[step.id]]);

  const [suggestions, setSuggestions] = useState<SuggestionState>(
    Object.fromEntries(
      Object.keys(steps).map((_, i) => [
        steps[i].id,
        {
          applied: false,
          value: "",
          completed: false,
          saved: false,
          loading: false,
          error: false,
        },
      ])
    )
  );

  const [direction, setDirection] = useState<"up" | "down">("up");

  useEffect(() => {
    if (!searchParams.has("step")) {
      // Todo set the step to the first step that is not completed
      const firstStep = steps.findIndex(
        (step) =>
          !documentVersion?.answers.find(
            (a: DocumentAnswer) => a.element === step.id
          )
      );
      let step = firstStep !== -1 ? firstStep + 1 : 1;
      updateSearchParams(setSearchParams, { step: step.toString() });
    }
  }, []);

  const hasInlineSuggestion =
    (step?.prompt &&
      ["markdownTable", "mermaid", "table"].includes(step?.element.type)) ||
    false;

  useEffect(() => {
    (async () => {
      // The check for device.description is needed because the device description is not set for the very first devices. Once all devices in the db have a description, this check can be removed
      // In combination with the DeviceDescriptionUpdateModal it makes sure that we don't generate suggestions for devices that don't have a description
      if (
        device &&
        documentVersion &&
        device.description &&
        documents &&
        step
      ) {
        const suggestion = getSuggestion(step, documentVersion, suggestions);
        const answer = getAnswer(
          templateId,
          answers,
          step,
          documentVersion,
          documents
        );

        if (!suggestion && step.prompt) {
          if (hasInlineSuggestion && answer) return;
          generateSuggestion(device, documents, documentVersion, step);
        }
      }
    })();
  }, [step, device, documentVersion, documents]);

  const nextStep = (
    step: TemplateElement,
    documentVersion: DocumentVersion
  ) => {
    handleSaveAnswer(answers[step.id], step, documentVersion);
    setDirection("up");
    updateSearchParams(setSearchParams, { step: `${currentStep + 1}` });
  };

  const prevStep = () => {
    setDirection("down");
    updateSearchParams(setSearchParams, { step: `${currentStep - 1}` });
  };

  const handleChange = (id: string, value: string) => {
    const step = steps.find((s) => s.id === id);
    if (step) {
      setAnswers((prevAnswer) => ({ ...prevAnswer, [id]: value }));
    } else {
      console.error("Step not found for id", id);
    }
  };

  const handleApply = (id: DocumentDataKey, suggestion: string) => {
    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [id]: {
        ...prevSuggestions[id],
        applied: true,
      },
    }));

    handleChange(id, suggestion);
  };

  const handleFinalStep = async (
    step: TemplateElement,
    docVersion: DocumentVersion
  ) => {
    setIsGenerating(true);

    // Set the status of the document version to completed for the local state to reflect the change and make the is completed check work
    docVersion.readyForApproval = true;

    // Save the answer for the last step
    const savedAnswer = await handleSaveAnswer(
      answers[step.id],
      step,
      docVersion
    );

    // TODO move this to onMutate or onSuccess of the saveAnswerMutation
    if (savedAnswer) {
      // Replace the answer in the document version with the saved answer
      docVersion.answers = docVersion.answers.map((a) =>
        a.element === step.id ? savedAnswer : a
      );

      // Or add the answer if it does not exist yet in the document version add the answer
      if (!docVersion.answers.find((a) => a.element === step.id)) {
        docVersion.answers.push(savedAnswer);
      }
    }

    //   Update the document version status to completed
    await patchDocumentMutation.mutateAsync({
      docId: docId,
      deviceId: deviceId,
      docVersionId: docVersion.id,
      data: { readyForApproval: true },
    });

    await docEngine.navigateToWizardOrQMS({ templateId, documentId: docId });

    // TODO handle the case where the document is not completed yet but somehow the final step is reached
  };

  const handleSaveAnswer = (
    answer: string | undefined | null,
    step: TemplateElement,
    documentVersion: DocumentVersion
  ): Promise<DocumentAnswer | undefined> => {
    if (!step.required && typeof answer !== "string") {
      answer = "";
    }

    const savedAnswer = getAnswerFromDocumentVersion(
      step.element,
      documentVersion
    );

    if (
      typeof answer !== "string" &&
      typeof savedAnswer !== "string" &&
      step.element.options.default
    ) {
      answer = step.element.options.default;
    }

    if (typeof answer === "string" && answer !== savedAnswer) {
      return saveAnswerMutation.mutateAsync({
        answer,
        step: step.element,
        documentVersion,
        deviceId,
        docId,
        answers,
      });
    } else {
      return Promise.resolve(undefined);
    }
  };

  const renderProgressBubbles = (
    step: TemplateElement,
    documentVersion: DocumentVersion
  ) => {
    return (
      <div className="flex flex-wrap gap-x-3">
        {steps.map((_, index) => {
          const stepEnabled =
            documentVersion.answers.find(
              (a: DocumentAnswer) =>
                a.element === steps[index - 1]?.id &&
                isStepComplete(steps[index], a.answer)
            ) || index === 0;
          const stepCompleted = documentVersion.answers.find(
            (a: DocumentAnswer) =>
              a.element === steps[index].id &&
              isStepComplete(steps[index], a.answer)
          );
          const backgroundColor =
            index + 1 === currentStep
              ? "bg-[#0089F9]"
              : stepCompleted
                ? "bg-[green]"
                : "bg-[gray]";
          return (
            <div
              key={index}
              className={`group ${
                stepEnabled ? "cursor-pointer" : "cursor-not-allowed"
              } py-2`}
              onClick={() => {
                if (stepEnabled) {
                  handleSaveAnswer(answers[step.id], step, documentVersion);
                  updateSearchParams(setSearchParams, { step: `${index + 1}` });
                }
              }}
            >
              <div
                className={`h-[10px] w-[30px] rounded-xl group-hover:opacity-80 ${backgroundColor}`}
              ></div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateSuggestion = async (
    device: Device,
    documents: Document[],
    documentVersion: DocumentVersion,
    step: TemplateElement
  ) => {
    // Set the loading state for the suggestion to true
    setSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [step.id]: {
        ...prevSuggestions[step.id],
        value: "",
        saved: false,
        applied: false,
        completed: false,
        isStatusMessage: false,
        statusMessageType: undefined,
        loading: true,
        error: false,
      },
    }));

    if (hasInlineSuggestion) {
      setAnswers((prevAnswers) => ({ ...prevAnswers, [step.id]: "" }));
    }

    // Start fetching the suggestion
    const generator = documentStore.generateSuggestion(
      templateId,
      step.id,
      device,
      documents,
      documentVersion
    );

    if (generator === undefined) return;

    let suggestion = "";

    try {
      let statusMessage: { status: string; message: string } | undefined =
        undefined;
      let previousWasStatusMessage = false;

      for await (const value of generator) {
        // check if the token is a status message object with the structure {status: "info", message: "some message"}

        if (value.startsWith("{") && value.endsWith("}")) {
          try {
            const event = JSON.parse(value);
            if (event.status && event.message) {
              statusMessage = event;
              previousWasStatusMessage = true;
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          statusMessage = undefined;
        }

        if (statusMessage) {
          suggestion = statusMessage.message;
        } else if (!statusMessage && previousWasStatusMessage) {
          suggestion = value;
          previousWasStatusMessage = false;
        } else {
          // TODO find out why this instead of previousState + value resolves the issue with the double backslashes
          suggestion += value;
        }

        setSuggestions((prevSuggestions) => ({
          ...prevSuggestions,
          [step.id]: {
            ...prevSuggestions[step.id],
            value: suggestion,
            loading: true,
            completed: false,
            isStatusMessage: !!statusMessage,
            statusMessageType: statusMessage?.status,
          },
        }));

        if (hasInlineSuggestion) {
          setAnswers((prevAnswer) => ({
            ...prevAnswer,
            [step.id]: suggestion,
          }));
        }
      }

      saveSuggestion.mutate({
        suggestion: suggestion,
        documentVersion,
        device,
        docId,
      });

      // If the suggestion is inline, save the answer as well as the newest suggestion is always also the answer
      if (hasInlineSuggestion) {
        setAnswers((prevAnswer) => ({
          ...prevAnswer,
          [step.id]: suggestion,
        }));
        saveAnswerMutation.mutate({
          answer: suggestion,
          step: step.element,
          documentVersion,
          docId,
          answers,
          deviceId,
        });
      }

      // Once the generator finishes, set the loading state to false and the completed state to true
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [step.id]: {
          ...prevSuggestions[step.id],
          completed: true,
          loading: false,
          saved: true,
        },
      }));
    } catch (error) {
      // Handle the error here
      console.error("Error in generator:", error);
      setSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [step.id]: {
          ...prevSuggestions[step.id],
          loading: false,
          completed: true,
          error: true,
        },
      }));
    }
  };

  if (
    !device ||
    !templateId ||
    !documentVersion ||
    !document ||
    !documents ||
    !user ||
    !tasks ||
    !step
  ) {
    return <MultiStepFormSkeleton />;
  }

  return (
    <div className="flex flex-1 flex-col gap-y-2">
      <div className="flex items-center  justify-between rounded-md bg-gray-100">
        <Button
          color="inherit"
          startIcon={<ChevronLeft />}
          // variant=""
          onClick={() => (currentStep === 1 ? navigate(-2) : navigate(-1))} // TODO: Fix navigation and make then make this navigate(-1)
        >
          Go Back
        </Button>
        <div className="flex gap-x-1">
          <IconButton onClick={prevStep} disabled={currentStep === 1}>
            <ChevronLeft fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => nextStep(step, documentVersion)}
            disabled={
              currentStep === steps.length ||
              !documentVersion.answers.find(
                (a: DocumentAnswer) => a.element === step.id
              )
            }
          >
            <ChevronRight fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-y-2">
        <div className="flex">
          <div className="flex flex-1 flex-col">
            <Typography variant="body1">
              {"Document Assistant: " + docConfig.docName}
            </Typography>
            {renderProgressBubbles(step, documentVersion)}
          </div>
        </div>

        {/* Hidden is needed to make slide relative to container work https://github.com/mui/material-ui/issues/31977#issuecomment-1253487009 */}
        <div className="flex flex-1 overflow-hidden" ref={containerRef}>
          {steps.map((step, index) => (
            <Step
              templateId={templateId}
              answers={answers}
              containerRef={containerRef}
              currentStep={currentStep}
              device={device}
              direction={direction}
              documents={documents}
              documentVersion={documentVersion}
              handleApply={handleApply}
              handleChange={handleChange}
              handleFinalStep={() => handleFinalStep(step, documentVersion)}
              // onRedo={() => handleRedo(documentVersion)}
              // onUndo={() => handleUndo(documentVersion)}
              hasInlineSuggestion={hasInlineSuggestion}
              index={index}
              isGenerating={isGenerating}
              key={index}
              nextStep={nextStep}
              onRegenerateSuggestion={generateSuggestion}
              step={step}
              steps={steps}
              suggestions={suggestions}
            />
          ))}
        </div>
      </div>
      <UpdateDeviceDescriptionModal device={device} />
    </div>
  );
};
