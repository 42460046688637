import { useDocEngine } from "@hooks";
import { Document, User } from "@models";
import { Accordion, AccordionSummary } from "@mui/material";
import { DocumentStatus } from "@types";
import { TaskAccordionCard } from "src/components/Roadmap/Task/TaskAccordionCard";
import { TaskAccordionDetails } from "src/components/Roadmap/Task/TaskAccordionDetails";
import { RoadmapTask } from "./../../../config";
import { TEMPLATE_TYPE } from "./../../../stores/models";
import {
  captureSentryMessage,
  getDocTypeStatus,
  isDocumentUnlocked,
} from "./../../../utils";

type Props = {
  deviceId: string;
  handleCloseCardDialog: () => void;
  task: RoadmapTask;
  documents: Document[];
  user: User;
};

export const TaskAccordion = ({
  handleCloseCardDialog,
  task,
  documents,
  user,
  deviceId,
}: Props) => {
  const docEngine = useDocEngine(deviceId);

  const handleTaskClick = async (task: RoadmapTask) => {
    // check if task.id is of type TEMPLATE_TYPE
    let docName: TEMPLATE_TYPE | undefined;
    if (Object.values(TEMPLATE_TYPE).includes(task.id as any))
      docName = task.id as TEMPLATE_TYPE;

    // if the the tasks is of type TEMPLATE_TYPE, we need to check more things. Otherwise we can just navigate to the link
    if (docName) {
      await docEngine.navigateToWizardOrQMS({
        templateId: docName,
      });
    } else {
      captureSentryMessage(
        "Task Modal was clicked but no where to go. Missing an old formId?"
      );
    }
  };

  // Check if every document that is mentioned inside the context config in the TemplateAssistantConfig is present in the documents array and marked as completed
  // We need to check if we have a valid template type because task id can also be a different string
  let templateType: TEMPLATE_TYPE | undefined;
  if (Object.values(TEMPLATE_TYPE).includes(task.id as any))
    templateType = task.id as TEMPLATE_TYPE;
  if (!templateType) return null;

  const { incompleteDocuments } = isDocumentUnlocked(templateType, documents);

  const docStatus = getDocTypeStatus({
    documents,
    templateId: templateType,
    user,
  });

  const isDocLocked = docStatus === DocumentStatus.LOCKED;

  const shouldBlockAccordionExpansion =
    (!isDocLocked && docStatus === DocumentStatus.AVAILABLE) ||
    docStatus === DocumentStatus.LIVE;

  return (
    <div key={task.title} className="flex flex-col gap-x-1 items-center">
      <Accordion
        sx={{
          width: "100%",
          "&.MuiAccordion-root": {
            boxShadow: "none",
            margin: "0",
          },
          "& .MuiButtonBase-root": {
            padding: "0",
            minHeight: "unset",
            "& .MuiAccordionSummary-content": {
              margin: "0",
            },
            "&.Mui-expanded": {
              minHeight: "unset",
            },
          },
        }}
        {...(shouldBlockAccordionExpansion && { expanded: false })}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          onClick={() => {
            if (!isDocLocked) handleTaskClick(task);
          }}
        >
          <TaskAccordionCard task={task} docStatus={docStatus} />
        </AccordionSummary>

        <TaskAccordionDetails
          handleCloseCardDialog={handleCloseCardDialog}
          task={task}
          incompleteDocuments={incompleteDocuments}
        />
      </Accordion>
    </div>
  );
};
