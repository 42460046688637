import {
  ArrowRightAlt,
  Inventory2Outlined,
  NoteAddOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import { AccordionDetails, Button, Card, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import {
  ASSISTANT_CONFIG,
  ROADMAP_CARDS,
  ROADMAP_TASKS,
  RoadmapTask,
  ROUTES,
  theme,
} from "../../../config";
import { TEMPLATE_TYPE } from "../../../stores/models";
import { updateSearchParams } from "../../../utils";

type Props = {
  handleCloseCardDialog: () => void;
  task: RoadmapTask;
  incompleteDocuments: TEMPLATE_TYPE[] | undefined;
};

export const TaskAccordionDetails = ({
  handleCloseCardDialog,
  task,
  incompleteDocuments,
}: Props) => {
  let [_, setSearchParams] = useSearchParams();

  return (
    <AccordionDetails className="mr-8">
      {incompleteDocuments?.length ? (
        <div
          className="flex flex-col gap-2"
          data-testid={`${task?.id}-dependencies`}
        >
          <div className="flex flex-col">
            <Typography variant="body1">Document Dependencies</Typography>
            <Typography variant="caption">
              Complete these documents to unlock this task.
            </Typography>
          </div>
          {incompleteDocuments?.map((c, index) => {
            const task = ROADMAP_TASKS.find((t) => t.id === c);
            const card = ROADMAP_CARDS.find((c) => c.id === task?.card);

            return (
              <Card
                data-testid={`${task?.id}-dependency-${index}`}
                key={`${card?.id}-${task?.id}`}
                onClick={() => {
                  handleCloseCardDialog();
                  updateSearchParams(setSearchParams, {
                    card: card?.title || "",
                    task: task?.id || "",
                  });
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  border: `1px solid ${theme.palette.grey[400]}`,
                  boxShadow: "none",
                  padding: "10px",
                  backgroundColor: "transparent",
                  width: "100%",
                  cursor: "pointer",

                  "& #go-to-task": {
                    transition: "transform 0.2s ease-in-out",
                    transform: "translateX(0px)",
                  },

                  "&:hover #go-to-task": {
                    transform: "translateX(3px)",
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {ASSISTANT_CONFIG[c].docName}
                <Typography
                  variant="caption"
                  className="flex items-center gap-1"
                  id="go-to-task"
                >
                  go to task
                  <ArrowRightAlt />
                </Typography>
              </Card>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col gap-2" data-testid={`${task?.id}-locked`}>
          <div className="flex flex-col">
            <Typography variant="body1">
              Oopsie, this document is not included in your free trial 😱
            </Typography>
          </div>
          <div className="flex flex-col gap-4">
            <Typography
              textAlign="start"
              variant="body2"
              className="flex items-center gap-3"
            >
              <NoteAddOutlined color="primary" fontSize="small" />
              Access to all generated documents.
            </Typography>
            <Typography
              textAlign="start"
              variant="body2"
              className="flex items-center gap-3"
            >
              <SchoolOutlined color="primary" fontSize="small" />
              1:1 expert support with our regulatory specialists.
            </Typography>
            <Typography
              textAlign="start"
              variant="body2"
              className="flex items-center gap-3"
            >
              <Inventory2Outlined color="primary" fontSize="small" /> Built in
              eQMS with streamlined document approval and revision handling.
            </Typography>
          </div>

          <Button variant="contained" component={Link} to={ROUTES.SUBSCRIPTION}>
            Upgrade
          </Button>
        </div>
      )}
    </AccordionDetails>
  );
};
